// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChooseInvo-module--SliderWidth--53157";
export var Trust = "WhyChooseInvo-module--Trust--3ba6f";
export var arrowAnimation = "WhyChooseInvo-module--arrowAnimation--9753b";
export var blackButton = "WhyChooseInvo-module--blackButton--ffd8a";
export var blueButton = "WhyChooseInvo-module--blueButton--fe5ad";
export var buton = "WhyChooseInvo-module--buton--6202a";
export var cardContent = "WhyChooseInvo-module--cardContent--a6f88";
export var cardCounting = "WhyChooseInvo-module--cardCounting--680fd";
export var cardDescription = "WhyChooseInvo-module--cardDescription--29935";
export var cardHoverEffect = "WhyChooseInvo-module--cardHoverEffect--e4806";
export var cardImage = "WhyChooseInvo-module--cardImage--08dc6";
export var cards = "WhyChooseInvo-module--cards--2345b";
export var container = "WhyChooseInvo-module--container--92fd9";
export var cta = "WhyChooseInvo-module--cta--dad24";
export var description = "WhyChooseInvo-module--description--3821b";
export var heading = "WhyChooseInvo-module--heading--4190d";
export var iconContainer = "WhyChooseInvo-module--iconContainer--6ea6e";
export var iconContainerLeft = "WhyChooseInvo-module--iconContainerLeft--4e92b";
export var indDec = "WhyChooseInvo-module--indDec--f429e";
export var portfolioArrowIcon = "WhyChooseInvo-module--portfolioArrowIcon--2d22f";
export var portfolioArrowIconCover = "WhyChooseInvo-module--portfolioArrowIconCover--5b9de";
export var portfolioArrowRightIconCover = "WhyChooseInvo-module--portfolioArrowRightIconCover--4806e";
export var sheildImgTop = "WhyChooseInvo-module--sheildImgTop--ead0d";
export var shieldTopBannerImg = "WhyChooseInvo-module--shieldTopBannerImg--81128";
export var skill = "WhyChooseInvo-module--skill--c2b54";
export var slideWrapper = "WhyChooseInvo-module--slideWrapper--8d14b";
export var text = "WhyChooseInvo-module--text--3d666";