// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--c50b8";
export var bannerHeading = "Banner-module--bannerHeading--0b05b";
export var bnrImg = "Banner-module--bnrImg--da80e";
export var btnsOuter = "Banner-module--btnsOuter--37674";
export var computerVisionBanner = "Banner-module--computerVisionBanner--e45cd";
export var curve = "Banner-module--curve--cfadd";
export var customeHead = "Banner-module--customeHead--4591a";
export var description = "Banner-module--description--8ebf1";
export var heading = "Banner-module--heading--19a86";
export var list = "Banner-module--list--cad85";
export var listItem = "Banner-module--listItem--73fd6";
export var mainTitle = "Banner-module--mainTitle--28297";
export var spanColor = "Banner-module--spanColor--12dc6";
export var transparentBtn = "Banner-module--transparentBtn--9c44a";