// extracted by mini-css-extract-plugin
export var card = "AIExpertiseSection-module--card--70328";
export var cardCounting = "AIExpertiseSection-module--cardCounting--edfc7";
export var cardHeading = "AIExpertiseSection-module--cardHeading--6caa2";
export var cardsContainer = "AIExpertiseSection-module--cardsContainer--19cb1";
export var cta = "AIExpertiseSection-module--cta--034e2";
export var description = "AIExpertiseSection-module--description--93a45";
export var heading = "AIExpertiseSection-module--heading--7a077";
export var highlight = "AIExpertiseSection-module--highlight--e2374";
export var imgContainer = "AIExpertiseSection-module--imgContainer--4d019";
export var sectionContainer = "AIExpertiseSection-module--sectionContainer--f8851";