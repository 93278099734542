// extracted by mini-css-extract-plugin
export var SliderWidth = "WordsFromClients-module--SliderWidth--3b8ee";
export var Trust = "WordsFromClients-module--Trust--b88d9";
export var blueText = "WordsFromClients-module--blueText--06610";
export var clientContent = "WordsFromClients-module--clientContent--22129";
export var coma = "WordsFromClients-module--coma--13d4c";
export var content = "WordsFromClients-module--content--a4b69";
export var customModal = "WordsFromClients-module--customModal--1e3fd";
export var dec = "WordsFromClients-module--dec--8327f";
export var description = "WordsFromClients-module--description--3597e";
export var designation = "WordsFromClients-module--designation--e7ce3";
export var dot = "WordsFromClients-module--dot--b5171";
export var heading = "WordsFromClients-module--heading--572c2";
export var headingContainer = "WordsFromClients-module--headingContainer--444aa";
export var iconContainer = "WordsFromClients-module--iconContainer--8d9b7";
export var iconContainerLeft = "WordsFromClients-module--iconContainerLeft--02d82";
export var logos = "WordsFromClients-module--logos--37a6c";
export var logosBg = "WordsFromClients-module--logosBg--76394";
export var name = "WordsFromClients-module--name--3417a";
export var playBtn = "WordsFromClients-module--playBtn--2df4b";
export var portfolioArrowIcon = "WordsFromClients-module--portfolioArrowIcon--0ef57";
export var portfolioArrowIconCover = "WordsFromClients-module--portfolioArrowIconCover--8e3b6";
export var portfolioArrowRightIconCover = "WordsFromClients-module--portfolioArrowRightIconCover--c8980";
export var view = "WordsFromClients-module--view--42da7";