import React from "react"
import * as styles from "./WhyChooseInvo.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Col } from "react-bootstrap"
import { Link } from "gatsby"
import Slider from "react-slick"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const WhyChooseInvo = ({ strapiData, projectEngRTest }) => {
  const [sliderKey, setSliderKey] = React.useState(0);

  React.useEffect(() => {
    const handleResize = () => {
      setSliderKey(prevKey => prevKey + 1); // Change key on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const settings = {
    infinite: false,
    autoplay: true,
    slidesToShow: 3,
    // centerMode: true,
    // centerPadding: "0px",
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
          slidesToScroll: 1,
        },
      },
    ],
  }


  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row>
            <Col xl={4} lg={12} md={12} sm={12} xs={12} className="p-0">
              <h2
                className={styles.heading}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
              />
            </Col>

            <Col xl={5} lg={6} md={12} sm={12} xs={12}>
              <div>
                <p className={styles.description}
                  style={{whiteSpace:'pre-line', color:'#6F6E70'}}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
                >
                {/* {strapiData?.subTitle} */}
              </p>
                <div xl={3} lg={6} md={12} sm={12} xs={12} className={styles.cta}>
                  {strapiData?.buttons.length > 0 &&
                  <Link to={strapiData?.buttons[0]?.url}>
                    <div className={styles.blueButton} style={{ color:'#010101'}}>
                      {strapiData?.buttons?.[0] && (
                        <p style={{ color:'#010101'}}>{strapiData?.buttons?.[0].title}
                        </p>
                      )}
                    </div>
                  </Link>}
                </div>
              </div>


            </Col>
            {/* <Col xl={3} lg={6} md={12} sm={12} xs={12} className={styles.cta}>
              {strapiData?.buttons.length > 0 &&
              <Link to={strapiData?.buttons[0]?.url}>
                <div className={`blueButton`}>
                  {strapiData?.buttons?.[0] && (
                    <p>{"Expand Your Squad"}</p>
                  )}
                </div>
              </Link>}
            </Col> */}
          </Row>
          <Row>
          <Slider key={sliderKey} {...settings} className={styles.SliderWidth}>

            {strapiData?.cards?.map((el, i) => (
              <Col key={i} xs={12} sm={12} md={3} className="d-flex">
                <div className={styles.slideWrapper} style={{width:'100%'}}>
                  <div
                    style={{ minHeight:'393px'}}
                    className={`${styles.cards} ${i % 2 === 0 ? styles.greyCard : styles.redCard
                      } ${styles.cardHover}`}
                  >
                    <div className={styles.cardContent}>
                      <div style={{marginBottom:'22px'}} className={styles.cardImage}>
                        {/* <div className={styles.cardCounting}>0{i + 1}</div> */}
                        <img
                            src={el?.image1[0]?.localFile?.publicURL}
                            style={{
                                height: "80px",
                                width: "80px",
                            }}
                            alt='icon'
                            />
                      </div>
                      <h3>{el?.title}</h3>
                    </div>
                    <div className={styles.cardDescription}>
                     <p>{el?.subTitle}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default WhyChooseInvo;
