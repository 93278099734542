import React from 'react';
import * as styles from './EngineerAHightPerformanceNLP.module.scss';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from 'react-bootstrap';

const EngineerAHightPerformanceNLP = ({ strapiData }) => {
    
    return (
        <Container className={styles.sectionContainer}>
            <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{textAlign:'center'}}>
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: strapiData?.title,
                        }}
                    />
                </Col>
            </Row>
            <Row className={styles.cardsContainer}>
                {
                    strapiData?.cards?.map((el, ind) => <Col xl={4} lg={4} md={6} sm={12} xs={12} key={`expertise_${ind}_${el?.title}`} className='d-flex'>
                    <div className={styles.card}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6}>
                                <div className={styles.cardCounting}><p>{`0${ind+1}`}</p></div>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} className={styles.imgContainer}>
                                <img src={el?.image1?.[0]?.localFile?.publicURL} className={styles.quesColorSelected} alt="cardImg" />
                            </Col>
                        </Row>
                        <p className={styles.cardHeading}>
                            {el?.title}
                        </p>
                        <p className={styles.description}>
                            {el?.subTitle}
                        </p>
                    </div>
                </Col>)
                }
                
              
            </Row>
        </Container>
    );
};

export default EngineerAHightPerformanceNLP;