// extracted by mini-css-extract-plugin
export var Trust = "WorkPortfolio-module--Trust--e0f23";
export var arrowIcon = "WorkPortfolio-module--arrowIcon--13039";
export var arrowImage = "WorkPortfolio-module--arrowImage--bbf8e";
export var arrowLink = "WorkPortfolio-module--arrowLink--e0b68";
export var btn = "WorkPortfolio-module--btn--5e8c7";
export var btn2 = "WorkPortfolio-module--btn2--3e653";
export var caseImages = "WorkPortfolio-module--caseImages--05f53";
export var clientImgs = "WorkPortfolio-module--clientImgs--75614";
export var content = "WorkPortfolio-module--content--bce37";
export var description = "WorkPortfolio-module--description--e1196";
export var headSearch = "WorkPortfolio-module--headSearch--6acee";
export var heading = "WorkPortfolio-module--heading--38642";
export var logoImages = "WorkPortfolio-module--logoImages--7022d";
export var logos = "WorkPortfolio-module--logos--0e418";
export var rowGap = "WorkPortfolio-module--rowGap--5dd34";
export var talkBtn = "WorkPortfolio-module--talkBtn--140a8";