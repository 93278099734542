import React from 'react';
import * as styles from './AIExpertiseSection.module.scss';
import { Link } from 'gatsby';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from 'react-bootstrap';

const AIExpertiseSection = ({ strapiData }) => {

    return (
        <div className={styles.sectionContainer}>
            <Container >
                <Row style={{justifyContent:'space-between'}}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h2
                        className={styles.heading}
                            dangerouslySetInnerHTML={{
                                __html: strapiData?.title,
                            }}
                        />
                        <div 
                        dangerouslySetInnerHTML={{
                            __html: strapiData?.description?.description,
                        }}
                        />
                    </Col>

                    <Col xl={5} lg={5} md={6} sm={12} xs={12} style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                        <img src={strapiData?.secImages?.[0]?.localFile?.publicURL} className={styles.quesColorSelected} alt="cardImg" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AIExpertiseSection;